[dir] .static-page-wrapper {
  padding: 12px;
}
.static-page-wrapper .content-box {
  color: #1F1F1F;
  min-height: 250px;
}
[dir] .static-page-wrapper .content-box {
  background-color: #FFF;
  border: 1px solid #1F1F1F;
  padding: 36px;
}
.static-page-wrapper .content-box .page-header {
  color: #D64100;
  font-size: 20px;
}
[dir] .static-page-wrapper .content-box .page-details .page-link {
  padding-top: 18px;
}
.static-page-wrapper .content-box .page-details .page-link .icon-right-arrow {
  display: inline-block;
  height: 18px;
  vertical-align: sub;
  width: 18px;
}
@media (min-width: 460px) {
  [dir] .static-page-wrapper {
    margin: 12px;
  }
  .static-page-wrapper .content-box .page-header {
    font-size: 20px;
  }
}
/*
 * Add any not found page specific styling here.
 */
